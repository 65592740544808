html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  padding-inline-start: 18px;
}
li {
  padding: 7px 0px;
}
li::marker {
  font-size: 14px !important;
}

.link {
  color: #015408;
  font-weight: 600;
}

input.input-fill,
select {
  padding: 6px 8px;
  appearance: none;
  width: 100%;
  margin-top: 2px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #f4f4f4;
  font-size: 16px;
}

.addToCartDiv input {
  height: 18px;
}

.bold {
  font-weight: 700;
}
.fieldGroupTitle {
  margin-bottom: -10px;
}

.error-screen {
  font-family: "Open Sans", sans-serif !important;
  padding-top: 50px;
  max-width: 750px;
  font-size: 14px;
  color: #333333;
  margin: auto;
  display: none;
  line-height: 1.5;
}

.error-screen h2 {
  font-size: 30px;
  font-weight: normal;
  line-height: 1.2;
}

.error-screen ul {
  padding-left: 15px;
  line-height: 1.7;
  margin-top: 0;
  margin-bottom: 10px;
}

.error-screen hr {
  color: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.error-screen-visible {
  display: block;
}

/* carousel plugin */
.carousel-arrow:first-child {
  transform: rotate(180deg);
}

.rec-carousel-item {
  display: flex;
  align-items: center;
  /* height: 200px; */
}

/* React carousel */
.carousel-status {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
}
.slider-wrapper {
  padding: 40px;
}
li.slide {
  min-width: 960px;
  padding: 0px;
  align-items: center;
  opacity: 0;
  transition: opacity 0.4s;
}
li.selected {
  opacity: 1 !important;
  transition: opacity 0.4s;
}
.galleryDialogImg {
  max-height: 180px !important;
  width: auto !important;
  margin: 0px auto;
}

.control-prev::before {
  position: absolute;
  left: 10px;
}
.control-next::before {
  position: absolute;
  right: 10px;
}
.control-prev.control-arrow::before {
  border-right: 8px solid #000 !important;
}
.control-next.control-arrow::before {
  border-left: 8px solid #000 !important;
}
.control-arrow {
  padding: 0px !important;
  width: 50%;
}
.control-arrow:hover {
  background: none !important;
}
.control-dots {
  z-index: 4 !important;
}
.dot {
  padding: 0px;
  width: 3px !important;
  height: 3px !important;
  margin: 0px 2px !important;
  opacity: 0.8;
  box-shadow: none !important;
  background: #000 !important;
}
.carousel-status {
  right: 10px !important;
}

.carouselDownload {
  padding: 8px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.15);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: background-color 0.25s;
  display: flex;
  align-items: center;
}
.carouselDownload:hover {
  background-color: rgba(0, 0, 0, 0.55);
}

@media (min-width: 500px) {
  .galleryDialogImg {
    max-height: 265px !important;
    width: auto !important;
    margin: 0px auto;
  }
  .dot {
    width: 4px !important;
    height: 4px !important;
    margin: 0px 3px !important;
  }
}
@media (max-width: 600px) {
  .addToCartDiv {
    position: absolute;
    bottom: 22px;
    left: 30px;
  }
}
@media (min-width: 600px) {
  .addToCartDiv {
    position: absolute;
    top: 27px;
    right: 30px;
  }
  .dot {
    width: 5px !important;
    height: 5px !important;
    margin: 0px 3px !important;
  }
}

@media (min-width: 800px) {
  .galleryDialogImg {
    max-height: 490px !important;
    width: auto !important;
    margin: 0px auto;
  }
  .dot {
    width: 6px !important;
    height: 6px !important;
    margin: 0px 4px !important;
  }
}
@media (min-width: 960px) {
  .galleryDialogImg {
    max-height: 610px !important;
    width: auto !important;
    margin: 0px auto;
  }
}
